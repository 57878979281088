import { ChangeEvent, FC, MouseEvent, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { warningMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useClickOutside } from 'hooks';
import { IOption } from 'interfaces';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import { roleOptions } from 'mock';
import { updateClanMemberThunk } from 'store/slices/clan/actions';
import { clansSelector } from 'store/slices/clan/selectors';
import {
  rolesReverseMap,
  rolesTranslateMap,
  rolesValuesMap,
} from 'types/player';

import { CollapseContainer } from 'components/CollapseContainer';
import { CopyIcon } from 'components/CopyIcon';
import { DeleteModal } from 'components/DeleteModal';
import { GeneratePassword } from 'components/GeneratePassword';
import { Button, Select, TextInput } from 'components/ui';
import {
  compareObjects,
  getUserFormFieldsError,
  maskPassword,
  notify,
} from 'utils';

interface InfoTableProps extends IPlayer {
  globalRole: EUserRole;
  onRemove: (id: string) => void;
  onUpdatePlayer: (value: IUpdatedPlayerData) => void;
  clanId: string;
  isNewClan?: boolean;
  newClanName?: string;
  editable: boolean;
}

export const InfoTable: FC<InfoTableProps> = ({
  globalRole,
  isNewClan = false,
  clanId,
  id,
  name,
  clan_id: originClanId,
  // launchedStreams,
  external_id,
  password,
  // status,
  roles,
  role: playerRole,
  onRemove,
  onUpdatePlayer,
  newClanName,
  editable,
}) => {
  const { clans } = useAppSelector(clansSelector);

  const clanOptions = clans.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const roleClan = useMemo(
    () => roles.find((role) => role.clan_id === clanId),
    [roles, clanId]
  );

  const rolePlayer = useMemo(
    () => (isNewClan ? playerRole : roleClan?.role_id),
    [isNewClan, playerRole, roleClan]
  );

  const roleInitialValue = roleOptions.find(
    (option) => option.value === rolePlayer
  );

  const nativeClanInitialValue = useMemo(
    () => clanOptions.find((option) => option.value === originClanId),
    [originClanId]
  );

  const initialValue = {
    name: name,
    login: external_id,
    password: password || '',
    role: roleInitialValue || {
      value: '',
      label: '',
    },
    originClan: nativeClanInitialValue || {
      value: '',
      label: '',
    },
  };

  const [info, setInfo] = useState(initialValue);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isShowDeleteButton, setShowDeleteButton] = useState(false);

  const [isFocus, setFocus] = useState(false);

  const [basicInfoFieldsErrorMessage, setBasicInfoFieldsErrorMessage] =
    useState({
      name: '',
      email: '',
      password: '',
    });

  const isShowButtons = !compareObjects(initialValue, info);

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const isRoot = globalRole === EUserRole.ROOT;

  const isAdmin =
    (globalRole === EUserRole.ADMIN && id !== rolesValuesMap.root) || isRoot;

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  // ToDo: temporarily hidden
  // const handleNavigate = (event: MouseEvent) => {
  //   event.stopPropagation();

  //   navigate(`/admin-panel/clan/records`, {
  //     state: {
  //       clanId,
  //     },
  //   });
  // };

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const handleChangeName = (value: string) => {
    setInfo((prev) => ({ ...prev, name: value }));
  };

  const handleChangeLogin = (value: string) => {
    setInfo((prev) => ({ ...prev, login: value }));
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setInfo((prev) => ({ ...prev, role: value }));
    }
  };

  const handleSelectNativeClan = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setInfo((prev) => ({ ...prev, originClan: value }));
    }
  };

  const handleGeneratePassword = (value: string) => {
    setInfo((prev) => ({ ...prev, password: value }));
  };

  const handleChangePassword = (value: string) => {
    setInfo((prev) => ({ ...prev, password: value }));
  };

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemovePlayer = () => {
    onRemove(id);

    handleCloseModal();
  };

  const handleFocus = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(true);
  };

  const handleBlur = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(false);
  };

  const handleClickSave = () => {
    if (isNewClan) {
      onUpdatePlayer({
        id,
        name: info.name,
        role: rolesReverseMap[info.role.value],
        login: info.login,
      });
    } else {
      if (
        info.login !== initialValue.login &&
        info.password === initialValue.password
      ) {
        notify.warning(warningMessages.UPDATE_LOGIN, {
          autoClose: 3000,
        });

        return;
      }

      const error = getUserFormFieldsError({
        password: info.password,
        name: info.name,
        email: info.login,
      });

      if (error.email.length || error.name.length || error.password.length) {
        setBasicInfoFieldsErrorMessage(error);

        return;
      }

      dispatch(
        updateClanMemberThunk({
          id,
          clanId,
          roles,
          newRoleId:
            info.role.value !== initialValue.role.value
              ? info.role.value
              : undefined,
          name: info.name !== initialValue.name ? info.name : undefined,
          password:
            info.password !== initialValue.password ? info.password : undefined,
          external_id:
            info.login !== initialValue.login ? info.login : undefined,
          originClan:
            info.originClan.value !== initialValue.originClan.value
              ? info.originClan
              : undefined,
        })
      );
    }
  };

  const handleClickCancel = () => {
    setInfo(initialValue);

    setBasicInfoFieldsErrorMessage({
      name: '',
      email: '',
      password: '',
    });
  };

  const convertedPassword =
    !info.password && !isFocus ? maskPassword('password') : info.password;

  const filteredRoleOptions =
    globalRole === EUserRole.ADMIN
      ? roleOptions.filter(
          (option) => option.label !== rolesTranslateMap[globalRole]
        )
      : roleOptions;

  const HiddenBlock = (
    <>
      <div className="min-h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
        <span className="tpg-c2 text-tpg_light">Логин</span>
        {isAdmin ? (
          <div className="flex flex-row items-start gap-[8px]">
            <TextInput
              value={info.login}
              placeholder="Введите логин латиницей"
              inputClassName="bg-ultrablack h-[20px] !pl-0"
              className="w-full"
              onChange={handleChangeLogin}
              error={basicInfoFieldsErrorMessage.email}
            />
            <CopyIcon value={external_id} />
          </div>
        ) : (
          <span className="tpg-c1 pointer-events-none">{info.login}</span>
        )}
      </div>
      <div className="min-h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
        <span className="tpg-c2 text-tpg_light">Пароль</span>
        <div className="flex flex-row justify-between items-start">
          {!isAdmin ? (
            <span className="tpg-c1 pointer-events-none">
              {maskPassword(info.password || 'password')}
            </span>
          ) : (
            <>
              <TextInput
                onChange={handleChangePassword}
                value={convertedPassword || ''}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Сгенерируйте пароль"
                inputClassName="bg-ultrablack h-[20px] !pl-0 !text-tpg_base"
                error={basicInfoFieldsErrorMessage.password}
              />
              <div className="flex flex-row gap-[8px]">
                <GeneratePassword onClick={handleGeneratePassword} />
                {info.password && <CopyIcon value={info.password} />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  const BaseInfo: FC<{ className?: string }> = ({ className }) => (
    <div className={className}>
      <div
        className={cn(
          'relative min-h-[61px] flex flex-col px-[16px] py-[10px]',
          { 'cursor-default': !editable }
        )}
      >
        <span className="tpg-c2 text-tpg_light">Позывной</span>
        {isAdmin && editable ? (
          <TextInput
            value={info.name}
            placeholder="Введите позывной кириллицей"
            inputClassName="bg-ultrablack h-[20px] !pl-0"
            onChange={handleChangeName}
            disabled={editable}
            error={basicInfoFieldsErrorMessage.name}
          />
        ) : (
          <span className="tpg-c1 pointer-events-none">{info.name}</span>
        )}
        {isShowDeleteButton && isAdmin && (
          <Button
            className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
            title="Удалить"
            onClick={handleOpenModal}
          />
        )}
      </div>
      {isAdmin && editable ? (
        <Select
          value={info.role}
          options={filteredRoleOptions}
          onSelect={handleSelectRole}
          placeholder="Роль"
          isOptionEmpty={false}
          className="pl-[16px] border-t border-solid border-t-dark_product rounded-b-[10px]"
        />
      ) : (
        <div className="min-h-[61px] cursor-default flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Роль</span>
          <span className="tpg-c1 pointer-events-none">
            {info.role.label || 'Не выбрана'}
          </span>
        </div>
      )}
      {editable && isRoot ? (
        <Select
          value={
            isNewClan
              ? { label: newClanName || '', value: newClanName || '' }
              : info.originClan
          }
          options={clanOptions}
          onSelect={handleSelectNativeClan}
          disabled={!isRoot}
          placeholder="Родное подразделение"
          emptyOptionText="Не выбрано"
          className={cn(
            'pl-[16px] border-t border-solid border-t-dark_product rounded-b-[10px]',
            {
              '!cursor-not-allowed !opacity-60': isNewClan,
            }
          )}
          isOptionSearch={true}
        />
      ) : (
        <div
          className={cn(
            'min-h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product',
            { hidden: !editable }
          )}
        >
          <span className="tpg-c2 text-tpg_light">Родное подразделение</span>
          <span className="tpg-c1 pointer-events-none">
            {info.originClan.label || 'Не выбрано'}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-[10px]">
      <div
        ref={containerRef}
        className={cn({ 'cursor-pointer': isAdmin })}
        onContextMenu={handleRightClick}
      >
        {editable ? (
          <CollapseContainer hiddenBlock={HiddenBlock}>
            <BaseInfo />
          </CollapseContainer>
        ) : (
          <BaseInfo className="border border-solid border-dark_product rounded-[10px]" />
        )}
        {isOpenDeleteModal && (
          <DeleteModal
            title="Вы уверены, что хотите удалить пользователя?"
            onClose={handleCloseModal}
            onRemove={handleRemovePlayer}
          />
        )}
      </div>
      {isShowButtons && (
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={handleClickCancel}
            title="Отмена"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button
            onClick={handleClickSave}
            title="Сохранить"
            className="w-full"
          />
        </div>
      )}
    </div>
  );
};
