import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';
import { getRecordsByClansThunk } from 'store/slices/records/actions';
import { TClanResponse } from 'types/clan';

import { FilterSection } from 'components/FilterSection';
import { DateTag } from 'components/FilterSection/DateTag';

import { ClansStreamList } from './ClansStreamList';

export const ClansScreen = () => {
  const [streamDate, setStreamDate] = useState<(string | null)[]>([]);

  const { clans } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchClansAndRecords = async () => {
      const res = await dispatch(getClansThunk());
      const clans = res.payload as TClanResponse[];

      if (clans.length) {
        await dispatch(
          getRecordsByClansThunk({ clanIds: clans.map((clan) => clan.id) })
        );
      }
    };

    fetchClansAndRecords();
  }, []);

  return (
    <div className="flex flex-col gap-[16px]">
      {!!streamDate.length && (
        <div className="relative">
          <FilterSection
            streamDate={streamDate}
            setStreamDate={setStreamDate}
          />
          <div className="absolute top-0 right-[24px] flex flex-col gap-[8px]">
            {!!streamDate.length && (
              <DateTag date={streamDate} setDate={setStreamDate} />
            )}
          </div>
        </div>
      )}
      {!!clans.length &&
        clans.map(({ id, name, work_area }) => (
          <ClansStreamList clan={name} id={id} place={work_area} key={id} />
        ))}
    </div>
  );
};
