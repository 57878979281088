export enum EventTypes {
  SET_CLAN_MEMBERS_BULK_BUTTON_CLICKED = 'SET_CLAN_MEMBERS_BULK_BUTTON_CLICKED',
  RESET_TABLE_SELECTION = 'RESET_TABLE_SELECTION',
  TABLE_SELECTION_CHANGED = 'TABLE_SELECTION_CHANGED',
}

export type SetClanMembersBulkButtonClickedEvent = {
  name: EventTypes.SET_CLAN_MEMBERS_BULK_BUTTON_CLICKED;
  id?: string;
};

export type ResetTableSelectionEvent = {
  name: EventTypes.RESET_TABLE_SELECTION;
  id?: string;
  data?: undefined; // No data for this event
};

export type TableSelectionChangedEvent = {
  name: EventTypes.TABLE_SELECTION_CHANGED;
  id?: string;
  data: {
    selectedAmount: number;
  };
};

export type Event =
  | SetClanMembersBulkButtonClickedEvent
  | ResetTableSelectionEvent
  | TableSelectionChangedEvent;
