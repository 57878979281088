import { errorMessages, successMessages } from 'constants/messages';
import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import axios from 'services/axios';
import { TClanResponse } from 'types/clan';

import { getMemberErrorMessage } from 'utils';

import { TSetBulkClanMembersInput, TSetClanMemberInput } from './types/clan';
import { requestHandler } from './utils/requestHandler';

export const getClans = requestHandler(
  () => axios.get<TClanResponse[]>('/clans'),
  { defaultErrorMessage: errorMessages.GET_CLANS }
);

export const getClan = requestHandler(
  (id: string) => axios.get<TClanResponse>(`clans/${id}`),
  { defaultErrorMessage: errorMessages.GET_CLAN }
);

export const getClanMembers = requestHandler(
  (clanId: string) => axios.get<IPlayer[]>(`/clans/${clanId}/members`),
  { defaultErrorMessage: errorMessages.GET_CLAN }
);

export const createClan = requestHandler(
  ({
    // coordinates,
    admin_id,
    name,
    work_area,
  }: Pick<IClan, 'name' | 'work_area'> & {
    admin_id: string;
  }) => {
    // ToDo: temporarily hidden
    // const location = coordinates[0].map(([lat, lon]) => ({
    //   lat,
    //   lon,
    // }));

    // ToDo: temporary data for executing the request
    const defaultLocation = [
      { lat: 41.1, lon: 41.2 },
      { lat: 76.2, lon: 29.3 },
      { lat: 74.7, lon: 49.5 },
      { lat: 41.1, lon: 41.1 },
    ];

    return axios.post(
      '/clans',
      {
        name,
        location: defaultLocation,
        admin_id,
        work_area,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },
  {
    defaultErrorMessage: errorMessages.CREATE_CLAN,
    defaultSuccessMessage: successMessages.CREATE_CLAN,
  }
);

export const updateClan = requestHandler(
  ({ coordinates, id, name, users_count, work_area }: IClan) => {
    const location = coordinates[0].map(([lat, lon]) => ({
      lat,
      lon,
    }));

    return axios.patch(
      `/clans/${id}`,
      {
        name,
        location,
        users_count,
        work_area,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },
  {
    defaultErrorMessage: errorMessages.UPDATE_CLAN,
    defaultSuccessMessage: successMessages.SAVE_DATA,
  }
);

export const deleteClan = requestHandler(
  (id: string) => axios.delete(`/clans/${id}`),
  {
    defaultErrorMessage: errorMessages.DELETE_CLAN,
    defaultSuccessMessage: successMessages.DELETE_CLAN,
  }
);

export const setClanMember = requestHandler(
  ({ clanId, memberId, roleId }: TSetClanMemberInput) =>
    axios.put(`/clans/${clanId}/members/${memberId}`, undefined, {
      params: {
        role_id: roleId,
      },
    }),
  {
    defaultErrorMessage: errorMessages.REGISTER_ERROR,
    callback: getMemberErrorMessage,
  }
);

export const setBulkClanMembers = requestHandler(
  (params: TSetBulkClanMembersInput) =>
    axios.put(`/clans/members/bulk`, {
      clan_ids: params.clan_ids,
      account_ids: params.account_ids,
      role_id: params.role_id,
    }),
  {
    defaultErrorMessage: errorMessages.SET_CLAN_MEMBERS_BULK_ERROR,
    defaultSuccessMessage: successMessages.SET_CLAN_MEMBERS_BULK_SUCCESS,
  }
);

export const deleteClanMember = requestHandler(
  ({ clanId, memberId }: Omit<TSetClanMemberInput, 'roleId'>) =>
    axios.delete(`/clans/${clanId}/members/${memberId}`),
  {
    defaultErrorMessage: errorMessages.DELETE_CLAN_MEMBER,
  }
);

export const deleteAllClansAccesses = requestHandler(
  (memberId: string) => axios.delete(`/accounts/${memberId}/clans`),
  {
    defaultErrorMessage: errorMessages.DELETE_MEMBER_ACCESSES,
    defaultSuccessMessage: successMessages.DELETE_ACCESSES,
  }
);
