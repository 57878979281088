import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as RightArrow } from 'images/newIcons/rightArrow.svg';
import {
  authActions,
  authSelector,
  getAccountMeThunk,
  updateMfaRequiredThunk,
} from 'store';
import { rolesValuesMap } from 'types/player';

import { Switch } from 'components/ui';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

import { getAccountSessions } from '../../api/sessions';
import { ISession } from '../../interfaces/sessions';
import { getFormattedDate } from '../../utils';

interface ProfileModalProps {
  onClose: () => void;
}

export const ProfileModal: FC<ProfileModalProps> = ({ onClose }) => {
  const { username, email, id, mfaRequired, roles } =
    useAppSelector(authSelector);

  const [isMfaRequired, setMfaRequired] = useState(!!mfaRequired);
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [sessionOpen, setSessionOpen] = useState(false);

  const dispatch = useAppDispatch();

  const fetchSessions = async () => {
    if (id) {
      const resp = await getAccountSessions(id);
      resp.data && setSessions(resp.data);
    }
  };

  const isAdmin = roles.find((role) => role.role_id !== rolesValuesMap.user);

  const handleTwoFactorChange = () => {
    setMfaRequired(!isMfaRequired);
  };

  const handleButtonClick = async () => {
    if (id) {
      await dispatch(
        updateMfaRequiredThunk({
          account_id: id,
          mfa_required: isMfaRequired,
        })
      );

      if (isMfaRequired) {
        dispatch(authActions.logout());
      }
      onClose();
    }
  };

  useEffect(() => {
    dispatch(getAccountMeThunk());
    fetchSessions();
  }, []);

  useEffect(() => {
    setMfaRequired(!!mfaRequired);
  }, [mfaRequired]);

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] w-[460px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <span className="tpg-h4 text-center">Настройки профиля</span>
        <div className="flex flex-col gap-[12px]">
          <div className="h-[40px] px-[16px] py-[10px] bg-light rounded-[5px]">
            <span className="tpg-c1 pointer-events-none">{username}</span>
          </div>
          <div className="h-[40px] px-[16px] py-[10px] bg-light rounded-[5px]">
            <span className="tpg-c1 pointer-events-none">{email}</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="tpg-c1">Двухфакторная аутентификация</span>
            <Switch
              disabled={mfaRequired && !isAdmin}
              checked={isMfaRequired}
              onChange={handleTwoFactorChange}
            />
          </div>
        </div>
        <div
          className="flex flex-row items-center cursor-pointer tpg-c1"
          onClick={() => setSessionOpen((prev) => !prev)}
        >
          <RightArrow
            className={cn('scale-110 [&>path]:fill-tpg_title', {
              'rotate-90': sessionOpen,
            })}
          />
          <span>Активные сессии</span>
        </div>
        <div
          className={cn('flex flex-col overflow-auto h-0 max-h-[200px]', {
            'h-full': sessionOpen,
          })}
        >
          {sessions.map((session, idx) => (
            <div key={`session-${idx}`} className="text-tpg_base py-2 tpg-c1">
              <div>ip: {session.ip}</div>
              <div>
                Последнее действие: {getFormattedDate(session.last_seen)}
              </div>
            </div>
          ))}
        </div>
        <Button
          onClick={handleButtonClick}
          title="Сохранить"
          className="w-full h-[40px]"
        />
      </div>
    </Modal>
  );
};
