import { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';
import { IAccountRole, IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { authSelector } from 'store/slices/auth/selectors';
import {
  deleteAllClansAccessesThunk,
  getClansThunk,
} from 'store/slices/clan/actions';
import { clansSelector } from 'store/slices/clan/selectors';
import { rolesValuesMap } from 'types/player';

import { filterOptionsByUniqueClan, getWordDayForm } from 'utils';

import { DeleteAllAccessesModal } from '../../components/DeleteAllAccessesModal';

import { AccessTable } from './components/AccessTable';
import { AddAccessCard } from './components/AddAccessCard';

interface AccessListProps {
  clans: IClanPlayer[];
  accountId: string;
  highPriorityRoles: IAccountRole[];
  onAddAccess: (value: IClanPlayer) => void;
  onRemoveAccess: (id: string) => void;
  onUpdateAccess: (value: IClanPlayer) => void;
  onClearAccesses: () => void;
  isAccessEditing: boolean;
  isBanned: boolean;
  roles: IAccountRole[];
  isNewPlayer?: boolean;
}

const isAdminForPlayer = (
  roles: IAccountRole[],
  clanId: string,
  accountId: string | null
) => {
  const clan = roles.find((role) => role.clan_id === clanId);

  return (
    clan?.role_name === EUserRole.ADMIN || accountId === rolesValuesMap.root
  );
};

export const AccessList: FC<AccessListProps> = ({
  clans,
  accountId,
  highPriorityRoles,
  onAddAccess,
  onRemoveAccess,
  onUpdateAccess,
  onClearAccesses,
  isAccessEditing,
  isBanned,
  roles,
  isNewPlayer,
}) => {
  const [isNewAccess, setNewAccess] = useState(false);

  const [isDeleteAllAccessesModalOpen, setIsDeleteAllAccessesModalOpen] =
    useState(false);

  const [clanOptions, setClanOptions] = useState<IOption[]>([]);

  const { id, role: accountRole } = useAppSelector(authSelector);

  const { clans: allClans } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  const filteredClans = useMemo(
    () =>
      clans.filter(
        (clan) =>
          (isAdminForPlayer(highPriorityRoles, clan.clanId, id) &&
            clan.roleId !== rolesValuesMap.root) ||
          isNewPlayer
      ),
    [clans]
  );

  const availableСlans = useMemo(
    () =>
      roles.flatMap((role) =>
        (role.role_name === EUserRole.ADMIN ||
          role.role_name === EUserRole.ROOT) &&
        role.clan_id
          ? {
              value: role.clan_id,
              label: role.clan_name,
            }
          : []
      ),
    [roles]
  );

  useEffect(() => {
    if (!allClans.length) {
      dispatch(getClansThunk());
    }
  }, [allClans]);

  useEffect(() => {
    if (accountRole !== EUserRole.ROOT) {
      const clanOptions = filterOptionsByUniqueClan(availableСlans, clans);

      setClanOptions(clanOptions);

      return;
    }

    const options = allClans.map(({ id, name }) => ({
      value: String(id),
      label: name,
    }));

    const clanOptions = filterOptionsByUniqueClan(options, clans);

    setClanOptions(clanOptions);
  }, [clans, allClans]);

  const wordForm = getWordDayForm(filteredClans?.length, [
    'доступ',
    'доступа',
    'доступов',
  ]);

  const handleOpenCardAccess = () => {
    setNewAccess(true);
  };

  const handleClose = () => {
    setNewAccess(false);
  };

  const handleDeleteAllAccessesClick = async () => {
    await dispatch(deleteAllClansAccessesThunk(accountId));
    setIsDeleteAllAccessesModalOpen(false);
    onClearAccesses();
  };

  const breakPoint = useBreakPoint();

  return (
    <div
      className={cn('w-6/12 min-h-full overflow-auto px-[16px]', {
        'w-full': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
    >
      <div className="flex flex-row justify-between bg-ultrablack py-[16px] sticky top-0 z-[2]">
        <span>{`${filteredClans?.length} ${wordForm}`}</span>
        <div className="flex items-center gap-3 justify-center">
          {filteredClans.length > 0 && (
            <div
              className="group cursor-pointer transition"
              onClick={() => setIsDeleteAllAccessesModalOpen(true)}
            >
              <TrashIcon className="[&>path]:fill-red w-[16px] h-[16px] [&>path]:transition" />
            </div>
          )}
          <div
            className="group cursor-pointer transition"
            onClick={handleOpenCardAccess}
          >
            {!!clanOptions.length &&
              (isAccessEditing || isNewPlayer) &&
              !isBanned && (
                <PlusIcon className="scale-125 group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
              )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] pb-[16px]">
        {isNewAccess && (
          <AddAccessCard
            accountId={accountId}
            clanOptions={clanOptions}
            onAddAccess={onAddAccess}
            onClose={handleClose}
            isNewPlayer={isNewPlayer}
          />
        )}
        {filteredClans?.map((clan) => (
          <AccessTable
            key={clan.clanId}
            accountId={accountId}
            onRemoveAccess={onRemoveAccess}
            onUpdateAccess={onUpdateAccess}
            isNewPlayer={isNewPlayer}
            isAccessEditing={isAccessEditing}
            {...clan}
          />
        ))}
      </div>
      {isDeleteAllAccessesModalOpen && (
        <DeleteAllAccessesModal
          accessesAmount={filteredClans.length}
          onClose={() => setIsDeleteAllAccessesModalOpen(false)}
          onSubmit={handleDeleteAllAccessesClick}
        />
      )}
    </div>
  );
};
