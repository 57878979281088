import React, { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { ReactComponent as CheckIcon } from 'images/newIcons/check.svg';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';

interface AdminTableProps {
  title: string;
  buttonTitle: string;
  listTitle: string;
  isShowFooterController: boolean;
  info: ReactNode;
  tabs: Record<string, ReactNode>;
  onSave?: () => void;
  onRemove?: () => void;
  isNewData?: boolean;
}

export const AdminTable: FC<AdminTableProps> = ({
  title,
  buttonTitle,
  isShowFooterController = false,
  info,
  tabs,
  onSave,
  onRemove,
  isNewData,
}) => {
  const breakPoint = useBreakPoint();
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    const firstTab = Object.keys(tabs)[0];

    if (firstTab) {
      setSelectedTab(firstTab);
    }
  }, [tabs]);

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  return (
    <div className="border w-full border-solid rounded-[10px] border-tpg_light h-full overflow-hidden">
      <div className="flex flex-row justify-between px-[12px] py-[10px] rounded-t-[10px] bg-dark border-solid border-b border-b-tpg_light">
        <span>{title}</span>
        <div className="flex flex-row gap-[16px]">
          {Object.keys(tabs).map((tab, i) => (
            <span
              key={`tab-${i}`}
              className={cn(
                'text-tpg_base cursor-pointer hover:text-bright_product transition',
                {
                  '!text-bright_product': tab === selectedTab,
                }
              )}
              onClick={() => handleSelectTab(tab)}
            >
              {tab}
            </span>
          ))}
        </div>
      </div>
      <div
        className={cn('flex flex-row h-[calc(100%-40px)] overflow-auto', {
          'h-[calc(100%-79px)]': isShowFooterController,
          'flex-col': isMobile,
        })}
      >
        {info}
        {tabs[selectedTab]}
      </div>
      {isShowFooterController && (
        <div className="flex flex-row justify-end px-[12px] py-[10px] rounded-[10px] bg-dark h-[40px] border-solid border-t border-t-tpg_light">
          <div className="flex flex-row gap-[12px]">
            {!isNewData && onRemove && (
              <div
                className="group gap-[4px] flex flex-row cursor-pointer items-center hover:text-bright_product transition"
                onClick={onRemove}
              >
                <TrashIcon className="group-hover:[&>path]:fill-bright_product w-[16px] h-[16px] [&>path]:transition" />
                <span className="tpg-c2">{buttonTitle}</span>
              </div>
            )}
            {onSave && (
              <div
                className="gap-[4px] flex flex-row cursor-pointer items-center hover:text-bright_product transition"
                onClick={onSave}
              >
                <CheckIcon className="[&>path]:fill-green" />
                <span className="tpg-c2">Сохранить</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
