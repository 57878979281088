import { FC, useState } from 'react';

import { Button, Modal, Select } from 'components/ui';

import { TSetBulkClanMembersInput } from '../../api/types/clan';
import { EUserRole } from '../../constants/profile';
import { IOption } from '../../interfaces';
import { roleOptions } from '../../mock';
import { rolesValuesMap } from '../../types/player';

interface BulkClanMembersModalProps {
  onClose: () => void;
  onSubmit: (params: Omit<TSetBulkClanMembersInput, 'account_ids'>) => void;
  clanOptions: IOption[];
}

export const BulkClanMembersModal: FC<BulkClanMembersModalProps> = ({
  onClose,
  onSubmit,
  clanOptions,
}) => {
  const [selectedRole, setSelectedRole] = useState<IOption>(roleOptions[1]);

  const [selectedClans, setSelectedClans] = useState<IOption[]>([]);

  const handleSelectClans = (value: IOption | IOption[]) => {
    if (Array.isArray(value)) {
      setSelectedClans(value);
    }
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);
    }
  };

  const handleSubmit = () => {
    const params: Omit<TSetBulkClanMembersInput, 'account_ids'> = {
      clan_ids: selectedClans.map((option) => option.value),
      role_id: selectedRole.value,
    };

    onSubmit(params);
  };

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] max-w-[530px] w-[530px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <div className="flex flex-col gap-[16px]">
          <div className="border border-solid rounded-[10px] border-dark_product">
            <Select
              value={selectedClans}
              options={clanOptions}
              onSelect={handleSelectClans}
              placeholder="Подразделение"
              isMultiple={true}
              isOptionSearch={true}
              className="pl-[16px] rounded-t-[10px] border-b border-solid border-b-dark_product"
            />
            <Select
              value={selectedRole}
              options={roleOptions.filter(
                (r) => r.value !== rolesValuesMap[EUserRole.ADMIN]
              )}
              onSelect={handleSelectRole}
              placeholder="Роль"
              isOptionEmpty={false}
              className="pl-[16px] rounded-b-[10px] rounded-t-[10px]"
            />
          </div>
          <div className="flex flex-row gap-[12px]">
            <Button
              onClick={onClose}
              title="Отмена"
              className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
            <Button
              onClick={handleSubmit}
              disabled={selectedClans.length === 0}
              title="Добавить"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
